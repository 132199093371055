body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.targetDropdown {
  height: 10px !important;
  color: #37474F !important;
  font-size: 12px !important;
  padding: 8px 14px !important;
  min-width: 271px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.targetDropdown.selected {
  background-color: #E8F5F9 !important;
}

.targetDropdown:hover {
  background-color: #E8F5F9 !important;
}

.applyButton {
  color: #19A2C5 !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.applyButton.disabled {
  opacity: 0.45;
  font-weight: 400 !important;
  text-transform: capitalize !important;
}

.applyButton:hover {
  background-color: #E8F5F9 !important;
  
}

.react-sort {
  height: 32px !important;
}

.navitem-active {
  color: #FFFFFF !important;
}


.custom-dropdown-root {

}
.custom-dropdown-menu {
  color: #37474F;
  font-size: 13px;
}

.custom-dropdown-control{
  border: 1px solid #CFD8DC;
  border-radius: 2px;
}

.custom-dropdown-placeholder {
  color: #37474F;
  font-size: 13px;
  font-family: Roboto;
}

.Collapsible__contentOuter{
}

.Collapsible__contentInner{
}

.toolbar-button::-moz-focus-inner {
  border-style: none;
}