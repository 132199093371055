.bar {
  /* fill: #9C27B0; */
}

.arc text {
  font: 10px sans-serif;
  text-anchor: middle;
}

.arc path {
  stroke: #fff;
}

.table-chart {
  border: 1px solid red;
}

 .chart .legend {
    fill: black;
    font: 14px sans-serif;
    text-anchor: start;
    font-size: 12px;
  }

  .chart text {
    fill: white;
    font: 10px sans-serif;
    text-anchor: end;
  }

  .chart .label {
    fill: black;
    font: 14px sans-serif;
    text-anchor: end;
  }

  .bar:hover {
    opacity: 0.9;
  }

  .axis path,
  .axis line {
    fill: none;
    /* stroke: #000; */
    shape-rendering: crispEdges;
  }

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.loader {
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    width: 110%;
    height: 13px;
    margin: 5px 0 0 0;
    position: relative;
}

/* .toolTip {
	position: absolute;
  display: none;
  min-width: 50px;
  height: auto;
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #26C6DA;
  padding: 8px;
  text-align: center;
  z-index: 999999;
  font-size: 0.7em;
} */

.toolTip {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: absolute;
  display: none;
  width: auto;
  height: auto;
  background: none repeat scroll 0 0 white;
  border: 0 none;
  border-radius: 8px 8px 8px 8px;
  box-shadow: -3px 3px 15px #888888;
  color: black;
  font: 12px sans-serif;
  padding: 5px;
  text-align: center;
}

.customLabel {
  position: absolute;
  text-align: center;
  font: 0.7em 'Roboto';
}

.customLabel:hover{
  z-index: 5000;
}

.modebar{
  display: none
}

.overview-chart{
}

.main-svg{
  background-color: transparent !important;
}
.xtick text{
  z-index: 1002;
  border: 1px solid brown;
  background-color: green !important;
}

.hoverlayer .axistext{
  z-index: 100;
  position: absolute;
}
