.tooltip {
  font-size: 12px;
  color: #607D8B !important;
  line-height: 18px;
  opacity: 1 !important;
  border: 1px solid #CFD8DC !important;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.35);
}

.__react_component_tooltip.type-light.border.place-right:before {
  border-right: 8px solid #CFD8DC;
}