.overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 1400;
}
.modal {
  max-width: 945px;
  height: 624px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  background-color: #F6F7F8;
  padding: 0;
}

.closeButton {
  top: 5px;
  right: 10px;
  width: 20px;
  height: 20px;
  fill: gray;
}