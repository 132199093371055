.my-projects-dropdown {
  background-color: green !important;
}
.my-projects-dropdown__value-container {
  padding-right: 0px !important;
}

.my-projects-dropdown__control {
  background-color: #455A64;
  min-width: 165px !important;
}

.my-projects-dropdown__control:hover {
  background-color: #455A64;
}
.my-projects-dropdown__dropdown-indicator {
  padding: 10px 8px 8px 0px !important;
}