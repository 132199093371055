.card {
  margin: 10px;
}

.card:hover {
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.35);
}

.menu-item {
 padding: 6px !important;
 font-size: 13px !important;
}

.MuiList-padding{
  padding: 0 !important;
}

.MuiButton-root {
  min-width: 32px !important;
  min-height: 32px !important;
  margin-right: 8px !important;
  padding: 0 !important;
}

.MuiMenu-paper{
  margin-left: 16px;
}

.MuiListItem-root {
  padding: 6px !important;
  font-size: 11px !important;
}