@keyframes animation {
    0%   {left: -231px;}
    25%  {left:-184px;}
    50%  {left:-137px;}
    75%  {left:-90px;}
    100% {left:0px;}
}

#container-mobile-menu {
   -webkit-animation-name: animation;
    -webkit-animation-duration: 0.1s;
    animation-name: animation;
    animation-duration: 0.1s;
}

.item-active {
    font-weight: 500;
}